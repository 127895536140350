import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { merge } from 'lodash';

import { SECRET_SLUG } from 'api/groups/constants';

import type { IAppData } from 'src/types';
import { actions } from 'store/application';
import { ApplicationController } from 'Group/controllers/application.controller';

import { FeedController, GroupController } from './controllers';
import settingsParams from './settingsParams';

const createController: CreateControllerFn = async (controllerContext) => {
  const { flowAPI, appData } = controllerContext;
  const { router, store } = appData as IAppData;
  const { setProps } = flowAPI.controllerConfig;

  const application = ApplicationController(controllerContext);
  const group = GroupController(controllerContext);
  const feed = FeedController(controllerContext);

  return {
    async pageReady() {
      await Promise.all([
        application.$.handleUserLogin(),
        feed.$.initComments(),
      ]);
      await resolve();
      setInitialProps();

      store.subscribe(updateStore);
    },

    updateConfig() {
      const groupId = flowAPI.settings.get(settingsParams.groupId);

      if (groupId !== SECRET_SLUG) {
        store.dispatch(actions.setAppData({ groupId }));
      }
    },

    onBeforeUnLoad() {
      feed.$.dispose();
      router.dispose();
    },
  };

  async function resolve() {
    const groupId = await group.group$
      .fetch(flowAPI.settings.get(settingsParams.groupId))
      .unwrap()
      .then(({ group }) => group.id);

    await feed.feed$.fetch(
      groupId as string,
      flowAPI.settings.get(settingsParams.postsCount),
    );

    store.dispatch(actions.setAppData({ groupId }));
  }

  function updateStore() {
    setProps({ store: store.getState() });
  }

  function setInitialProps() {
    feed.$.bindComments();
    setProps(
      merge(
        {
          fitToContentHeight: true,
          store: store.getState(),
        },
        application,
        group,
        feed,
      ),
    );
  }
};

export default createController;
