import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  CommentsControllerApi,
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import type { IAppData } from 'src/types';

import * as feed from 'store/feed';
import { selectPinnedItem } from 'store/feed/selectors';
import { selectStateDeclarations } from 'store/application/selectors';

export function FeedController(params: ControllerParams) {
  const { environment } = params.flowAPI;
  const { router, store } = params.appData as IAppData;
  const { isSSR } = environment;

  const states = selectStateDeclarations(store.getState());
  let commentsApi: CommentsControllerApi;

  return {
    $: {
      async initComments() {
        commentsApi = await initializeCommentsController(
          params.controllerConfig,
          {
            shouldAutoBindStateToSetProps: false,
            appDefinitionId: params.controllerConfig.appParams.appDefinitionId,
            httpClient: params.flowAPI.httpClient,
          },
        );

        commentsApi.watch.pagination.onChange(handleCommentsChange);
      },
      bindComments() {
        commentsApi.bindStateToSetProps();
      },
      dispose() {
        commentsApi.unmountAllResources();
      },
    },
    feed$: {
      fetch,
      react,
      unreact,
      pin,
      unpin,
      subscribe,
      unsubscribe,
    },
  };

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feed.actions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }

  async function fetch(groupId: string, limit: number = 5) {
    return store.dispatch(
      feed.thunks.fetch({
        groupId,
        commentsApi,
        cursor: {
          limit,
        },
      }),
    );
  }

  function subscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.subscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function unsubscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unsubscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  async function pin(groupId: string, feedItemId: string) {
    const pinnedItem = selectPinnedItem(store.getState());

    if (pinnedItem) {
      await unpin(groupId, pinnedItem.feedItemId as string);
    }

    return store.dispatch(
      feed.thunks.pin({
        feedItemId,
        groupId,
      }),
    );
  }

  function unpin(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unpin({
        feedItemId,
        groupId,
      }),
    );
  }

  function react(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.react({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function unreact(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.unreact({
        code,
        groupId,
        feedItemId,
      }),
    );
  }
}

export type IFeedController = Omit<ReturnType<typeof FeedController>, '$'>;
