import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { JoinRequest } from '@wix/ambassador-social-groups-v2-group-member/types';

import * as groups from 'store/groups';
import type { IAppData } from 'src/types';

export function GroupController(params: ControllerParams) {
  const { store } = params.appData as IAppData;

  return {
    group$: {
      join,
      fetch,
      fetchQuestions,
    },
  };

  async function join(params: JoinRequest) {
    store.dispatch(groups.thunks.join(params));
  }

  async function fetchQuestions(groupId: string) {
    store.dispatch(groups.thunks.fetchQuestions(groupId));
  }

  function fetch(groupId: string) {
    return store.dispatch(groups.thunks.fetchGroup(groupId));
  }
}

export type IGroupController = ReturnType<typeof GroupController>;
